import {HttpClient} from '@angular/common/http';
import {Injectable, signal, Signal, WritableSignal} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Customer} from '../models/customers.model';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomerInfoService {
  private readonly _currentCustomer$: WritableSignal<Customer> = signal(undefined);

  private readonly serviceUrl: string = environment.baseUrl + '/customers/customer';

  constructor(private readonly http: HttpClient) {
  }

  public fetchCustomerInfo(): Observable<Customer> {
    return this.http
      .get<Customer>(this.serviceUrl + '/getCustomerInfo')
      .pipe(
        tap((customer) => {
          this._currentCustomer$.set(new Customer(customer));
        }),
        catchError(() => {
          console.error('Loading customer data failed');
          return of(undefined);
        })
      );
  }

  // ------------------------------------------ ACCESSORS --------------------------------------------------- //

  public get currentCustomer$(): Signal<Customer> {
    return this._currentCustomer$.asReadonly();
  }
}
